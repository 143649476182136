<template>
  <div class="course-info" v-if="orderInfo">
    <div class="title">课程信息</div>
    <div class="info-content">
      <div class="infoList">
        <span>课程名称：</span>
        <p>{{orderInfo.loCourseInfo.courseGroupName}}</p>
      </div>
      <div class="infoList classInfo" v-if="orderInfo.loCourseInfo.whetheHaveClassManager === 1">
        <span>班级信息：</span>
        <p>
          <span v-for="(item,index) in flightNameList" :key="index">{{item}}</span>
        </p>
        
      </div>
      <!-- <div class="infoList">
        <span>上课形式：</span>
        <p>{{orderInfo.loCourseInfo.categoryName}}{{orderInfo.loCourseInfo.classWay}}</p>
      </div> -->
      <!-- <div class="infoList">
        <span>主讲：</span>
        <p v-for="(titem, tindex) of orderInfo.loCourseInfo.teachers" :key="tindex">
          {{titem.teacherName}}<template v-if="tindex !== orderInfo.loCourseInfo.teachers.length - 1">、</template> 
        </p>
      </div> -->
      <div class="infoList">
        <span>上课地址：</span>
        <p>{{orderInfo.loCourseInfo.classLocation}}{{orderInfo.loCourseInfo.classWay}}</p>
      </div>
      <div class="infoList" v-if="orderInfo.loCourseInfo.whetheHaveClassManager !== 1">
        <span>上课时间：</span>
        <p>{{orderInfo.loCourseInfo.classTime}}</p>
      </div>
      <div class="infoList">
        <span>课程价格：</span>
        <p class="red">￥{{orderInfo.loCourseInfo.currentPrice}}</p>
      </div>
      <div class="infoList">
        <span>课程备注：</span>
        <p >
          {{orderInfo.loCourseInfo.subtitle}}
        </p>
      </div>
      
    </div>
  </div>
</template>
<script>
import { getFlightName } from '@/api/api'
export default {
  name:"CourseInfo",
  props: {
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      flightIds: [],
      flightNameList: [],
        
    }
  },
  mounted() {
      let orderInfo = this.$store.state.orderInfo || null
      if(orderInfo) {
        this.flightIds = orderInfo.classInfo
        if(orderInfo.loCourseInfo.whetheHaveClassManager === 1){
          this.getFlightName()
        }
      }
    
  },
  methods:{
    async getFlightName() {
      let params = {
        flightIds: this.flightIds
      }
      let response = await getFlightName(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      let str = response.data
      let strs = str.split(",");
      this.flightNameList = strs
      
    },
  }
  
}
</script>
<style scoped>
.course-info{
  /* width:94%; */
  padding:0 3%;
  background:#fff;
}
.course-info .title{
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  line-height: 0.5rem;
  padding:0.2rem 0;
  border-bottom:1px #f2f2f5 solid;
}
.info-content{
  padding-bottom: 0.2rem;
}
.info-content .infoList{
  display: flex;
  font-size: 14px;
  color:#333;
  line-height: 0.6rem;
  text-align: left

}
.info-content .infoList span{
  width:24%;
  color:#999;
  flex-grow: 0;
  flex-shrink: 0;
}
.info-content .infoList .red{
  color:#FF3F47;
  font-weight: bold;
}
.info-content .infoList p span{
  display: block;
  width: 100%;
  color: #333;
}
</style>