<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-05-25 11:14:21
 * @LastEditTime : 2023-02-21 14:51:18
 * @LastEditors  : BigBigger
-->
<template>
  <div class="payType">
    <div class="title">
      <!-- <span>支付形式</span> -->
    </div>
    <div class="info-content" v-if="courseInfo">
      <!-- <div class="pay-item " @click="payWay = 1" :class="{'pay-item-active' : payWay === 1}">
        全款：￥{{courseInfo.currentPrice}}
        <p class="tag"></p>
      </div>
      <div class="pay-item"  @click="payWay = 2" :class="{'pay-item-active' : payWay === 2}" v-if="courseInfo.payWay === 2">
        定金：￥{{courseInfo.deposit}}
        <p class="tag"></p> -->

      <!-- ios 刷新机制离谱，增加空白组件 -->
      <van-cell style="padding: 0;"></van-cell>
      <van-radio-group :value="value">
        <div class="infoList" v-if="courseInfo.payWay === 2 || courseInfo.payWay === 3">
          <span class="sp">定金：</span>
          <span class="money">￥{{courseInfo.deposit}} <i v-if="!(courseInfo.throughPay === 1 && throughPay === 1) && (courseInfo && courseInfo.payWay !== 1) && !['1575666087956000769'].includes(courseInfo.id)">推荐定金;{{isZJ ? '（报名现场支付课程余款）' : ''}}</i></span>
          <van-radio checked-color="#FF3F47" :name="2" @click="change(2)"></van-radio>
        </div>

        <div class="infoList" v-if="!(courseInfo.throughPay === 1 && throughPay === 1) && (courseInfo.payWay === 1 || courseInfo.payWay === 2)">
          <span class="sp">全款：</span>
          <span class="money">￥{{getPrice}}</span>
          <van-radio checked-color="#FF3F47" :name="1" @click="change(1)"></van-radio>
        </div>
      </van-radio-group>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name:'PayType',
  props: {
    discountInfo: Object,
    courseInfo: Object,
    throughPay: Number,
    // defaultValue: Number,
    value: Number,
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data(){
    return{
      payWay: this.defaultValue,
      // courseInfo: null,
      isZJ: process.env.VUE_APP_AREA === 'zj' ? true : false,
    }
  },
  computed: {
    ...mapState({
      // userInfo: state => state.orderInfo.loUserInfo || {},
      // throughPay: state => state.orderInfo.throughPay,
      // discountInfo: state => state.orderInfo.loDiscountInfo || {},
    }),
    getPrice() {
      if (!this.courseInfo) return '';
      // if (
      //   this.courseInfo.interviewFlag === 3
      //   && this.courseInfo.payWay === 1
      //   && this.discountInfo.studentsPreferential === 1
      //   && this.discountInfo.field01
      // ) {
      //   return this.courseInfo.afterOldStudentsPrice + ` (老学员优惠 ${this.courseInfo.oldStudentsPrice})`;
        // return subtract(numeric(this.courseInfo.currentPrice, 'BigNumber'), numeric(this.courseInfo.oldStudentsPrice, 'BigNumber')) + ` (老学员优惠 ${this.courseInfo.oldStudentsPrice})`;
      // }
      if (
				this.discountInfo.studentsPreferential === 1 &&
				this.discountInfo.field01
			) {
				return this.personalInfo.afterOldStudentsPrice;
			}
			return this.courseInfo.payMoney;
    }
  },
  methods: {
    getPayWay() {
      return this.payWay
    },
    change(value) {
      console.log(value);
      this.$emit('change', value);
    }
  },
  watch: {
    throughPay: {
      handler(val) {
        if (val === 1) this.$emit('change', 2);
      },
      immediate: true,
    }
  },
  mounted() {
    // this.courseInfo = JSON.parse(window.localStorage.getItem('orderInfo') || '').loCourseInfo
    // this.courseInfo = this.$store.state.orderInfo.loCourseInfo
    // if (this.$store.state.payWay) {
    //   this.payWay = this.$store.state.payWay
    // } else {
    if (this.courseInfo.payWay === 1) {
      this.$emit('change', 1);
    }
    if (this.courseInfo.payWay === 3) {
      this.$emit('change', 2);
    }
    if (this.courseInfo.payWay === 2 && ![1,2].includes(this.value)) {
      this.$emit('change', 1);
    }
    //   this.$store.dispatch('setPayWay', this.payWay)
    // }
  },
}
</script>
<style lang="less" scoped>
.payType{
  // width:94%;
  // padding:0 3%;
  background:#fff;
  // margin-top:0.25rem;
  // padding-bottom: 0.8rem;
  .pay-top {
    margin: 0.2rem 0;
    font-size: 14px;
  }
}
.payType .title{
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  line-height: 0.65rem;
  margin:0.2rem 0;
  border-bottom:1px #f2f2f5 solid;
  display:flex;
  justify-content: space-between;
}
.info-content .infoList{
  display: flex;
  font-size: 14px;
  color:#333;
  line-height: 0.8rem;
  text-align: left

}
.info-content .infoList .van-radio{
  // margin-right:0.3rem;
  flex-grow: 0;
  flex-shrink: 0;
}
.info-content .infoList .sp{
  flex-grow: 0;
  flex-shrink: 0;
}
.info-content .infoList .money{
  color:#FF3F47;
  flex-grow:2

}
.info-content .infoList .money i{
  font-size: 12px;
  font-style: normal;
}
</style>