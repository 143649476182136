<template>
  <div class="job-info">
    <div class="title">
      <span>岗位选择：</span>
      <span class="red" v-if="!jobInfo">请选择岗位</span>
      <span class="red" v-else>已选择</span>
      <span class="select-btn" @click="selectJob">选择</span>
    </div>
    <div class="info-content">
      <div class="infoList">
        <span>部门名称：</span>
        <p v-if="jobInfo">{{jobInfo.departmentName}}</p>
        <p v-else class="nochange">暂未选择</p>
      </div>
      <div class="infoList">
        <span>单位名称：</span>
        <p v-if="jobInfo">{{jobInfo.employer}}</p>
        <p v-else class="nochange">暂未选择</p>
      </div>
      <div class="infoList">
        <span>职位名称：</span>
        <p v-if="jobInfo">{{jobInfo.positionName}}</p>
        <p v-else class="nochange">暂未选择</p>
      </div>
      <div class="infoList">
        <span>招聘人数：</span>
        <p v-if="jobInfo">{{jobInfo.numberCandidates}}</p>
        <p v-else class="nochange">暂未选择</p>
      </div>
      <div class="infoList">
        <span>职位代码：</span>
        <p v-if="jobInfo">{{jobInfo.jobCode}}</p>
        <p v-else class="nochange">暂未选择</p>
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  name:"JobInfo",
  data() {
    return {
      jobInfo: null
    }
  },
  mounted() {
    this.getJobInfo();
  },
  methods:{
    getJobInfo() {
      let orderInfo = this.$store.state.orderInfo || null
      if(orderInfo) {
        this.jobInfo = orderInfo.loJboInfo
      }
    },
    selectJob() {
      this.$router.push({path:'/course/signup/selectjob'})
    }
  }
  
}
</script>
<style scoped>
.job-info{
  /* width:94%; */
  padding:0 3%;
  background:#fff;
  margin-top:0.25rem;
}
.job-info .title{
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  line-height: 0.5rem;
  padding:0.2rem 0;
  border-bottom:1px #f2f2f5 solid;
  display:flex;
  justify-content: space-between;
}
.job-info .title .red{
  color:#FF3F47;
  flex-grow:2
}
.select-btn{
  width:1rem;
  height:0.4rem;
  line-height: 0.4rem;
  border:1px #FF3F47 solid;
  font-size: 12px;
  color:#FF3F47;
  text-align: center;
}
.info-content .infoList{
  display: flex;
  font-size: 14px;
  color:#333;
  line-height: 0.6rem;
  text-align: left

}
.info-content .infoList span{
  width:24%;
  color:#999;
  flex-grow: 0;
  flex-shrink: 0;
}
.info-content .infoList .nochange{
  color:#bbb;
}
</style>