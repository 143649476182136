<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2022-02-14 16:22:06
 * @LastEditors  : BigBigger
-->
<template>
  <div class="address-box">
    <div class="title">
      <span>收货地址</span>
      <span class="addressManagement" @click="pageJump">管理</span>
    </div>
    <div class="address-contant" v-if="defaultAddress">
      <div class="address-info">
        <span>{{defaultAddress.name}}</span>
        <span>{{defaultAddress.phone}}</span>
      </div>
      <div class="address-info">
        <img src="@/assets/images/h5-icon/icon7.png">
        <span>{{defaultAddress.provinceName}} {{defaultAddress.cityName}} {{defaultAddress.regionName}} {{defaultAddress.detailAddress}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getDefaultAddressList } from '@/api/api'
export default {
  name:'AddressInfo',
  data() {
    return {
      defaultAddress: null
    }
  },
  mounted() {
    this.getDefaultAddressList()
  },
  methods:{
    pageJump() {
      this.$router.push({path: '/course/signup/selectAddress'})
    },
    getId() {
      return this.defaultAddress.id
    },
    getAddress() {
      return this.defaultAddress
    },
    async getDefaultAddressList() {
      let response = await getDefaultAddressList()
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.defaultAddress = response.data || null
    },
  }

}
</script>
<style scoped>
.address-box{
  /* width:94%; */
  margin-top:0.25rem;
  background:#ffffff;
  padding:0 3%;
}
.address-box .title{
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  line-height: 0.5rem;
  padding:0.2rem 0;
  border-bottom:1px #f2f2f5 solid;
  display:flex;
  justify-content: space-between;
}
.address-box .addressManagement{
  font-size: 14px;
  color:#ADADAD;
  font-weight: 400;
}
.address-contant{
  padding:0.2rem 0;
}
.address-contant .address-info{
  line-height: 0.5rem;
  padding:0.05rem 0;
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items:center;
}
.address-contant .address-info img{
  width:0.4rem;
  margin-right: 0.2rem;
}
.address-contant .address-info span{
  margin-right:0.26rem;
}
</style>